import React, { Component, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Container } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import '../../../assets/style/scss/state-machine.scss';
import { injectIntl } from 'react-intl';
import routes from '../../../routes';
import LoadingModal from '../../components/Commons/LoadingModal';

const Footer = React.lazy(() => import('./Footer'));
const Header = React.lazy(() => import('./Header'));

class Layout extends Component {

    render() {
        return (
            <div className="app">
                <div className="header">
                    <Container disableGutters maxWidth={false}>
                        <Suspense fallback={<CircularProgress />}>
                            <Header />
                        </Suspense>
                    </Container>
                </div>
                <div className="body">
                    <main>
                        <Container disableGutters maxWidth={false}>
                            <Suspense fallback={<LoadingModal loading={true} />}>
                                <Switch>
                                    {routes.map((route, idx) => {
                                        if(route.component){
                                            return <Route key={idx} path={route.path} name={route.name} exact={route.exact} render={route.component} />;
                                        }
                                        return null;
                                    })}
                                </Switch>
                            </Suspense>
                        </Container>
                    </main>
                </div>
                <div className="footer">
                    <Container disableGutters maxWidth={false}>
                        <Suspense fallback={<CircularProgress />}>
                            <Footer />
                        </Suspense>
                    </Container>
                </div>
            </div>
        );
    }
}

export default injectIntl(Layout);