//ENVIRONMENT POR DEFECTO, CAMBIA SEGUN EL HOST DONDE CORRE EL FRONT
let ENVIRONMENT = "DEV";
const ENVIRONMENTS = {
    DEV: "DEV",
    SAND: "SAND",
    PROD: "PROD"
};
let RCAPCHAK="6Lc7KKMfAAAAAAGvQpnrkJ9Rxkp0et4DAqzJZWZq";

const TAR_DEV_HOST = "localhost";
const TAR_SAND_HOST = "sandbox.tarmexico.com";
const TAR_PROD_HOST = "tarmexico.com";

let merchOrkesta = '';
let publiOrkesta = '';


//Seteo de ambiente en base a URL del navegador.
const RUNNING_FRONT = window.location.origin;
if(RUNNING_FRONT.includes(TAR_DEV_HOST)){
    ENVIRONMENT = ENVIRONMENTS.DEV;
    RCAPCHAK="6Lc7KKMfAAAAAAGvQpnrkJ9Rxkp0et4DAqzJZWZq";
    merchOrkesta = 'mch_89d235df1c944396808a73564883cde7';
    publiOrkesta = 'pk_test_s2jtj1klwwmijfj1vyuvsult466hj5n5';
}
else if(RUNNING_FRONT.includes(TAR_SAND_HOST)) {
    ENVIRONMENT = ENVIRONMENTS.SAND;
    RCAPCHAK="6Ld4WlceAAAAAO2D7LmBMf281C6jeQQLiZluGhZc";
    merchOrkesta = 'mch_89d235df1c944396808a73564883cde7';
    publiOrkesta = 'pk_test_s2jtj1klwwmijfj1vyuvsult466hj5n5';
}
else if(RUNNING_FRONT.includes(TAR_PROD_HOST)){
    ENVIRONMENT = ENVIRONMENTS.PROD;
    RCAPCHAK="6Lff6RAmAAAAANmaOgAXy-Iy_T1FecJNvwelImz_";
    merchOrkesta = 'mch_78e238dcdb9e44d2ab93a6422837f45c';
    publiOrkesta = 'pk_r7lxi3kk29czcwmwdhrepzf6m0echy5y';
}
console.log("ENVIRONMENT SETTING UP");
console.log(RUNNING_FRONT + " -> " + ENVIRONMENT);

const AIRLINE = "TAR";
const CMS_AIRLINE = "tar";
const VERSION_API_IBE = "1.0";
const VERSION_API_CMS = "v1.0";
const LANGUAGE = localStorage.getItem("language") ? localStorage.getItem("language"):  "es";

const GA_DEV_KEY = "UA-104039318-1";
const GA_SAND_KEY = "UA-104039318-2";
const GA_PROD_KEY = "UA-104039318-3";

const GTM_SAND = "GTM-5QTBH6J";
const GTM_PROD = "GTM-MZGPBH9";

//Value of Payments 
const StroreMax = 30000.00;

const MUI_PRO_KEY = "ec15c56ce9ce3ca83f602c91518a3c49Tz01OTU2MCxFPTE3MDc2MDc1NzIyMTIsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=";

export {
    ENVIRONMENT,
    RCAPCHAK,
    ENVIRONMENTS,
    TAR_DEV_HOST,
    TAR_SAND_HOST,
    TAR_PROD_HOST,
    AIRLINE,
    CMS_AIRLINE,
    VERSION_API_CMS,
    VERSION_API_IBE,
    LANGUAGE,
    GA_DEV_KEY,
    GA_SAND_KEY,
    GA_PROD_KEY,
    GTM_SAND,
    GTM_PROD,
    RUNNING_FRONT,
    StroreMax,
    MUI_PRO_KEY,
    merchOrkesta,
    publiOrkesta
};