//Import all traduction files
import app from './app.json';
import header from './header.json';
import footer from './footer.json';
import home from './home.json';
import widget from './widget.json';
import calendar from './calendar.json';
import passengers from './passengers.json';
import seating from './seating.json';
import payment from './payment.json';
import reservation from './reservation.json';
import car from './car.json';
import fares from './faresDetail.json';
import invoice from './invoice.json';
import reset from './reset.json';
import marketplace from './marketplace.json'
import promotions from './promotions.json'

import errors from './api-messages.json';

//Export unique object from all files
export default { ...app, ...header, ...footer, ...home, ...widget, ...calendar, ...passengers, ...seating, ...payment, ...reservation, ...car, ...errors, ...fares, ...invoice, ...reset, ...marketplace, ...promotions };
