import React, { Component } from 'react';
import { Grid, CircularProgress } from '@mui/material';

const LoadingModal = (props) => {
	const { loading, fill = false } = props;
	if (loading) {
		return (
			<Grid
				container
				justifyContent='center'
				style={{
					backgroundColor: fill ? '#FFFFFF' : 'rgba(0, 0, 0, 0.2)',
					width: '100vw',
					height: '100vh',
					position: 'fixed',
					zIndex: '999999',
					top: '0',
					left: '0',
				}}
			>
				<CircularProgress
					color='secondary'
					style={{
						width: '60px',
						height: '60px',
						marginTop: 'calc(50vh - 100px)',
						marginBottom: 'calc(50vh - 100px)',
					}}
				/>
			</Grid>
		);
	}

	return <></>;
};

export default LoadingModal;
